//
export var giftHomeData = [{
  name: "Women's Day Special",
  image: '/giftbox/Womens Day Special.jpeg',
  to: ''
}, {
  name: "Valentine's Day Special",
  image: '/giftbox/Valentines Day Special.jpeg',
  to: ''
}];
export var giftBoxes = [{
  title: 'MOTHER’S DAY SPECIAL 2021',
  products: ['Best Mom Ever Scented Candle', 'Lavender Bath Salt', 'Chocolates', 'Dream Catcher', 'Satin Mask', 'Sheet Mask']
}, {
  title: "WOMEN'S DAY SPECIAL BOX 2021",
  products: ['Who Runs The World/ Girls Just Wanna Have Fun Scented Candles', 'Lavender Bath Salt', 'Satin Scrunchies', 'Satin Mask', 'Sheet Mask', 'Hand Cream']
}, {
  title: 'VALENTINES DAY SPECIAL BOX 2021',
  products: ['Scented Candle of your choice', 'Bath Salt of your choice', 'Satin mask', 'Satin Scrunchies', 'Brownies', 'Pinata Heart', 'Chocolate Coated Cookies']
}, {
  title: 'READYMADE GIFT BOXES',
  products: ['Scented Candle of your choice', 'Bath Salt of your choice', 'Satin mask', 'Satin Scrunchies', 'Sheet Mask', "Victoria's Secret Mist", 'Dream Catcher', 'Reversible Octo Plushie']
}];